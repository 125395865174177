import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { ROUTES } from '@/constants/routes';
import { useRole } from '@/hooks/useRole';
import useUserRole from '@/hooks/useUserRole';
import EmptyLayout from '@/layouts/empty';
import { useSelectUser } from '@/store/user/selectors';
import type { PageWithAuthType, PageWithLayoutType } from '@/types/pages';

const Page: PageWithLayoutType & PageWithAuthType = () => {
  const router = useRouter();

  const user = useSelectUser();
  const { role: baseRole } = useRole();
  const role = useUserRole(baseRole, user, []);
  useEffect(() => {
    if (['BORROWER', 'BORROWER_UNDERWRITING'].includes(role)) {
      router.push(ROUTES.DASHBOARD);
    } else {
      router.push(ROUTES.SERVICING_LOANS);
    }
  }, [router]);

  return <LoadingAnimationPlat />;
};

Page.layout = EmptyLayout;
Page.requireAuth = true;

export default Page;
